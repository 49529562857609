
import {setup, Vue} from 'vue-class-component'
import moment from 'moment'
import {file} from '@/composables/file'
import {LimitPageDefault, LimitPageVariants} from '@/composables/constants'
import {list, SortingDefault} from '@/composables/file/list'
import {Sorting} from '@/services/types'
import {FileTypes} from '@/services/constants'
import {positiveInt} from '@/composables/mask'

export default class History extends Vue {
  showOptions = false
  page = 1
  limit = LimitPageDefault.value
  sorting: Sorting = SortingDefault
  fileTypes: FileTypes[] = [FileTypes.EXPORT]

  created(): void {
    this.clear()
  }

  ctx = setup(() => {
    return {...list(), ...file(), LimitPageVariants}
  })


  formatDate(date: string) {
    if (date) {
        return moment(date).format('DD.MM.YYYY')
    }
  }

  async download(fileId: number, fileName: string) {
    const image = await this.ctx.getFileBase64ById(fileId)
    const link = document.createElement('a')
    link.href = image
    link.setAttribute('download', fileName)
    link.click()
  }

  /**
   * Общая часть
   */

  // триггер на обновление таблицы для новой страницы
  async changePage(): Promise<void> {
    this.page = positiveInt(this.page)
    await this.fetch()
  }

  // Применить фильтры
  async apply() {
    this.page = 1
    await this.fetch()
  }

  // Сбросить фильтры до дефолта
  async clear() {
    this.ctx.reset()
    this.ctx.setFileType(this.fileTypes)
    this.cancel()
    await this.fetch()
  }

  // Отменить введенные фильтры
  cancel() {
    this.page = this.ctx.meta.offset / this.ctx.meta.limit + 1
    this.limit = this.ctx.meta.limit
    this.sorting = this.ctx.sorting

    this.close()
  }

  close() {
    this.showOptions = false
  }

  scroll() {
    document.getElementById('table')?.scrollTo({top: 0, behavior: 'smooth'})
  }

  async fetch() {

    this.ctx.setLimit(this.limit)
    this.ctx.setPage(this.page)
    this.ctx.setSorting(this.sorting)

    await this.ctx.fetch()
    this.close()
    this.scroll()
  }
}
