import {ref} from '@vue/reactivity'
import OrdersService from '@/services/OrdersService'
import {File} from '@/composables/file/models'

export function orders() {
    const isLoading = ref(false)

    async function getExport(orderStatusId: number, exportedAtFrom?: string, exportedAtTo?: string): Promise<File> {
      isLoading.value = true
        const result = await OrdersService.getExport(orderStatusId, exportedAtFrom, exportedAtTo)
        return new Promise<File>((resolve) => {
          resolve(result.data.data as File)
        }).finally(() => isLoading.value = false)
    }

  return {
    isLoading,
    getExport,
  }
}