import {ref} from '@vue/reactivity'
import {ResponseData, Sorting} from '@/services/types'
import {AxiosError} from 'axios'
import {LimitPageDefault} from '@/composables/constants'
import {showError} from '@/composables/utils'
import {File} from '@/composables/file/models'
import {OrderByVariants, FileTypes} from '@/services/constants'
import FileService,{GetsListParams as Params} from '@/services/FileService'



type Meta = {
    limit: number,
    offset: number,
    sort_by: string,
    order_by: OrderByVariants,
    total: number,
    page: {
        current: number,
        total: number,
    },
}

enum SortByVariants {
    CREATED_AT = 'created_at'
}


const SortingVariants: Sorting[] = [
    {
        name: 'Сначала новые',
        sort_by: SortByVariants.CREATED_AT,
        order_by: OrderByVariants.DESC,
    },
    {
        name: 'Сначала старые',
        sort_by: SortByVariants.CREATED_AT,
        order_by: OrderByVariants.ASC,
    }
]

export const SortingDefault: Sorting = SortingVariants[0]

const MetaDefault = {
    limit: LimitPageDefault.value,
    offset: 0,
    order_by: SortingDefault.order_by,
    sort_by: SortingDefault.sort_by,
    total: 0,
    page: {
        current: 1,
        total: 1,
    },
} as Meta



export function list() {
    const list = ref({} as { [key: string]: File })
    const isLoading = ref(false)
    const sorting = ref(SortingDefault)
    const meta = ref(MetaDefault)
    const fileTypes = ref([] as FileTypes[])

    function fetch(): void {

        const params = {
            limit: meta.value.limit,
            offset: meta.value.offset,
            order_by: meta.value.order_by,
            sort_by: meta.value.sort_by,
            file_types: fileTypes.value
        } as Params

        isLoading.value = true
        FileService.getFileList(params)
            .then((response: ResponseData) => {
                list.value = response.data.data.items
                meta.value = response.data.data.meta
            })
            .catch((e: AxiosError) => showError(e))
            .finally(() => isLoading.value = false)
    }

    function setFileType(types: FileTypes[]) {
        fileTypes.value = types
    }

    function setPage(value: number) {
        meta.value.offset = (value - 1) * meta.value.limit
    }

    function setLimit(value: number) {
        meta.value.limit = value
    }

    function setSorting(value: Sorting) {
        sorting.value = value
        meta.value.sort_by = value.sort_by
        meta.value.order_by = value.order_by
    }

    function reset(){
        setLimit(LimitPageDefault.value)
        setPage(1)
        setSorting(SortingDefault)
    }


    return {
        // getters
        list,
        isLoading,
        meta,
        sorting,
        sortingVariants: SortingVariants,
        fileTypes,

        // mutations
        setPage,
        setLimit,
        setSorting,
        setFileType,
        reset,

        // actions
        fetch
    }
}