
import {Options, Vue} from 'vue-class-component'
import Export from '@/components/orders/export/Export.vue'
import History from '@/components/orders/export/History.vue'

enum TabNames {
  EXPORT = 'export',
  HISTORY = 'history',
}

@Options({
  components: {
    History,
    Export,
  },
})
export default class List extends Vue {
  tab = TabNames.EXPORT
  tabNames = TabNames
}
