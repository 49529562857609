
import {Options, setup, Vue} from 'vue-class-component'
import {file} from '@/composables/file'
import {File} from '@/composables/file/models'
import {orders} from '@/composables/orders'
import {OrderStatusId, OrderStatusName} from '@/composables/orders/types'
import DatePicker from '@/components/core/DatePicker.vue'
import moment from 'moment'

@Options({
  components: {
    DatePicker
  },
})

export default class Export extends Vue {
  orderStatusId = OrderStatusId
  orderStatusName = OrderStatusName

  statuses = [
    {value: this.orderStatusId.CREATED, name: this.orderStatusName.CREATED},
    {value: this.orderStatusId.EXPORTED, name: this.orderStatusName.EXPORTED}
  ]
  selectedStatusId = ''

  dateFrom: Date = {} as Date
  dateTo: Date = {} as Date

  file: File = {} as File

  ctx = setup(() => {
    return {...file(), ...orders()}
  })

  updateDateFrom(newDate: Date) { this.dateFrom = newDate }
  updateDateTo(newDate: Date) { this.dateTo = newDate }

  changeExportType() { this.file = {} as File }

  formatDate(date: string) {
    if (date) {
        return moment(date).format('DD.MM.YYYY')
    }
  }

  async execute() {
    const statusId = Number(this.selectedStatusId)
    this.file = {} as File
    if (statusId === this.orderStatusId.CREATED) {
      this.file = await this.ctx.getExport(statusId)
    }
    else if (statusId === this.orderStatusId.EXPORTED) {
      const exportedAtFrom = moment(this.dateFrom).format('YYYY-MM-DD 00:00:00')
      const exportedAtTo = moment(this.dateTo).format('YYYY-MM-DD 23:59:59')
      this.file = await this.ctx.getExport(statusId, exportedAtFrom, exportedAtTo)
    }
  }

  async download() {
    if (this.file) {
      const linkFile = await this.ctx.getFileBase64ById(this.file.id)
      const link = document.createElement('a')
      link.href = linkFile
      link.setAttribute('download', this.file.name)
      link.click()
      URL.revokeObjectURL(linkFile)
      URL.revokeObjectURL(link.href)
    }
  }
}
